import './Reel.css'
import Bar from '../../components/Bar/Bar.js'
import MobileNavigation from '../../components/MobileNavigation.js'
import ScrollToTop from '../../components/ScrollToTop.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from "framer-motion"
import React from 'react';

export default function Contact(){
  return(
    <HelmetProvider>
      <motion.div exit={{ opacity:0 }} animate={{ opacity:1 }} initial={{ opacity:0 }}
    transition={{ ease: [0,.68,0,1.01],duration: 1.2 }}>
      <Helmet>
       <meta charSet="utf-8" />
       <title>Lucas Zorzetto ― Demo Reel</title>
      </Helmet>
      <Bar/>
      <MobileNavigation/>
      <ScrollToTop/>
      <div className='project-content'>
      <div className="video-embed">
      <iframe src="https://player.vimeo.com/video/961812612" width="100%" height="960" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
      </div>
      <hr className="divider-big"></hr>
      <p id="description">Collection of my best work I did so far condensed in one video!</p>
      <hr className="divider-big"></hr>
      <a1>Song: Overmono - Is U <br></br><br></br> <b>Lazy Eyes</b> - Animation and design <br></br> <b>TvOne1</b> - <b>Óticas Diniz</b> - Animation and design <br></br> <b>UEDT</b> - Animation and design <br></br> <b>Halos</b> - Animation and design <br></br> <b>Ravie</b> - <b>Luther Systems</b> - Animation <br></br> <b>Inner Door</b> - Animation and design <br></br> <b>Newton's Pendulum</b> - Animation and design <br></br> <b>Lazy Eyes</b> - Animation and design <br></br> <b>Secret Branches</b> - Animation and design <br></br> <b>Lazy Eyes</b> - Animation and design <br></br> 🔴🟠🟡🟢🔵🟣 - Animation and design <br></br> <b>TvOne1</b> - Animation and design</a1>
      </div>
    </motion.div>
    </HelmetProvider>
  )
}