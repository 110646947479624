import './Error.css'
import Bar from '../../components/Bar/Bar'
import MobileNavigation from '../../components/MobileNavigation'
import Close from '../../../src/components/Close/Close' 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from "framer-motion"

export default function Error(){
  return(
    <HelmetProvider>
      <motion.div exit={{ opacity:0 }} animate={{ opacity:1 }} initial={{ opacity:0 }} 
      transition={{ ease: [0,.68,0,1.01],duration: .5 }}>
      <Helmet>
       <meta charSet="utf-8" />
       <title>Lucas Zorzetto ― Error</title>
      </Helmet>
        <MobileNavigation/>
        <Bar/>
        <Close/>
       <p id='error'>Page not found :(</p>
  </motion.div>
  </HelmetProvider>
  )
}