import { FaInstagram, FaBehance, FaLinkedinIn, FaVimeoV } from "react-icons/fa";
import { MdMail } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import classes from './MobileNav.module.css'
import React from "react";

const NavLinks = () => {

return(
  <div>
  <div className={classes.mobNavigation}>
    <header className="mobar-header">
      <div className="header-placehodler"></div>
      <ul>
        <NavLink to="/">
          <li>Work</li>
        </NavLink>

        <NavLink to="/about">
        <li>About</li>
        </NavLink>

        <NavLink to="/reel">
          <li>Reel</li>
        </NavLink>

        <NavLink to='/play'>
          <li>Play</li>
        </NavLink>

        <div className={classes.mobSocialsMenu}>
        <a className={classes.socialIcons} href="https://instagram.com/lucaszzrt" target="_blank">
        <FaInstagram color='black' size={40}/>
        </a>

        <a className={classes.socialIcons} href="https://be.net/lucaszzrt" target="_blank">
        <FaBehance color='black' size={40}/>
        </a>

        <a className={classes.socialIcons} href="https://vimeo.com/lucaszzrt" target="_blank">
        <FaVimeoV color='black' size={40}/>
        </a>

        <a className={classes.socialIcons} href="https://linkedin.com/in/lucaszzrt" target="_blank">
        <FaLinkedinIn color='black' size={40}/>
        </a>

        <a className={classes.socialIcons} href="mailto:zzrtcontact@gmail.com">
        <MdMail color='black' size={40}/>
        </a>
      </div>
    </ul>
    </header>
    </div>
    </div>
  );
}
export default NavLinks;