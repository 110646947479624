import ScrollToTop from '../../../../components/ScrollToTop'
import Close from '../../../../components/Close/Close'
import Bar from '../../../../components/Bar/Bar'
import MobileNavigation from '../../../../components/MobileNavigation'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from "framer-motion"

export default function SomeLoops(){
    return(
      <HelmetProvider>
      <motion.div exit={{ opacity:0 }} animate={{ opacity:1 }} initial={{ opacity:0 }}
      transition={{ ease: [0,.68,0,1.01],duration: 1.5 }}> 
        <Helmet>
         <meta charSet="utf-8" />
         <title>Lucas Zorzetto ― Some Loops</title>
        </Helmet>
            <Bar/>
            <MobileNavigation/>
            <ScrollToTop/>
            <Close/>
            <div className='project-content'>
        <div className="container-project">
            <div className="video-embed-sqr">
            <iframe src="https://player.vimeo.com/video/556895072?h=f848d3e5bd" width="100%" height="960" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="video-embed-sqr">
            <iframe src="https://player.vimeo.com/video/557698570?h=6bba37b865" width="100%" height="960" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="video-embed-sqr">
            <iframe src="https://player.vimeo.com/video/567263654?h=902776c54d" width="100%" height="960" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>
        <hr class="divider-small"></hr>
            <h1>Some Loops</h1>
            <a8>2021</a8>
            <hr class="divider-small"></hr>
            <p id="description">Personal project about shapes in loop. <br></br> The objective of the project was to test some skills of motion graphics and design with nonsense ideas.</p>
        <hr class="divider-small"></hr>
          <h1>Role</h1>
          <a1>Animation and Design</a1>
          </div>
        </div>
      </motion.div>
      </HelmetProvider>
    )
}