import './About.css'
import ScrollToTop from '../../components/ScrollToTop'
import MobileNavigation from '../../components/MobileNavigation'
import Bar from '../../components/Bar/Bar'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from "framer-motion"

export default function About(){
  return(
    <HelmetProvider>
    <motion.div exit={{ opacity:0 }} animate={{ opacity:1 }} initial={{ opacity:0 }}
    transition={{ ease: [0,.68,0,1.01],duration: 1.5 }}>
      <Helmet>
       <meta charSet="utf-8"/>
       <title>Lucas Zorzetto ― About</title>
      </Helmet>
      <MobileNavigation/>
        <Bar/>
        <ScrollToTop/>
    <div className="container-about">
      <img className='avatar' src="/Avatar.webp" alt="Avatar"/>
        <aside>
          <p id="hello">Hello!</p>
          <p>My name is Lucas Zorzetto, I'm Motion Designer based in Araçatuba, São Paulo, Brazil.</p>
          <p>Being passionate about creating, I've been venturing myself into video and audio production since 2016. Since then I've gone through video editing, music production and currently, design and animation.</p>
          <p>In the span of recent years I had the honor to work for studios and clients from US, EU and regional companies, such as Ravie, Luther Systems, GCDS, Band, Unimed, among others.</p>
          <p>I'm always willing to nice projects and collaborations! Don't hesitate to get in touch if you have something in mind.</p>
          <p>Reach out to me at:&nbsp;<a id='link' href="mailto:zzrtcontact@gmail.com">zzrtcontact@gmail.com↗</a>.</p>
        </aside>
        </div>
  </motion.div>
  </HelmetProvider>
  )
}