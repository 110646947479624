import ScrollToTop from '../../../../components/ScrollToTop'
import Bar from '../../../../components/Bar/Bar'
import MobileNavigation from '../../../../components/MobileNavigation'
import Close from '../../../../components/Close/Close'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from "framer-motion"

export default function Equilibrate(){
  return(
    <HelmetProvider>
    <motion.div exit={{ opacity:0 }} animate={{ opacity:1 }} initial={{ opacity:0 }}
    transition={{ ease: [0,.68,0,1.01],duration: 1.5 }}> 
      <Helmet>
       <meta charSet="utf-8" />
       <title>Lucas Zorzetto ― Equilibrate</title>
      </Helmet>
          <Bar/>
          <MobileNavigation/>
          <ScrollToTop/>
          <Close/>
          <div className='project-content'>
          <div className="video-embed-sqr">
          <iframe src="https://player.vimeo.com/video/653451578?h=ed00a7b7be" width="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
          </div>
          <div className="container-project">
          <hr className="divider-small"></hr>
          <h1>Equilibrate</h1>
          <a1>2021</a1>
          <p id="description">Last personal project I did in 2021.<br></br>I was trying to change my animation style a little bit with abstract shapes.</p>
          <hr class="divider-small"></hr>
          <h1>Role</h1>
          <a1>Animation and Design</a1>
          </div>
      </div>
    </motion.div>
    </HelmetProvider>
    )
}