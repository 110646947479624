import './Bar.css'
import Logo from '../Logo/Logo'
import { MdMail } from 'react-icons/md'
import { FaInstagram, FaBehance, FaLinkedinIn, FaVimeoV } from "react-icons/fa";
import React from "react";
import { NavLink } from 'react-router-dom'

const Bar = () => {
  return (
      <div className='sidebar'>
      <Logo/>
        <header className='sidebar-header'>
          <nav>
            <NavLink to="/">Work</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/Reel">Reel</NavLink>
            <NavLink to="/play">Play</NavLink>
          </nav>
          <div className="menu">
            <a href="https://instagram.com/lucaszzrt" target="_blank" title='Instagram'>
              <FaInstagram color='black' size={30}/>
            </a>
            <a href="https://behance.net/lucaszzrt" target="_blank" title='Behance'>
              <FaBehance color='black' size={30}/>
            </a>
            <a href="https://vimeo.com/lucaszzrt" target="_blank" title='Vimeo'>
              <FaVimeoV color='black' size={30}/>
            </a>
            <a href="https://linkedin.com/in/lucaszzrt" target="_blank" title='LinkedIn'>
              <FaLinkedinIn color='black' size={30}/>
            </a>
            <a href="mailto:zzrtcontact@gmail.com" title='Contact'>
                <MdMail color='black' size={30}/>
            </a>
          </div>
        </header>
      </div>
  )
}
export default Bar;
