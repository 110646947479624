import React, { useState } from 'react';
import { IoCloseSharp, IoMenuSharp } from "react-icons/io5";
import classes from './MobileNav.module.css';
import NavLinks from './NavLinks';
import { Link } from 'react-router-dom';

const MobileNavigation = () => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
    /*
    if (!open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    */
  };

  const hamburguerIcon = (
    <IoMenuSharp className={classes.Hamburguer} size={50} onClick={toggleMenu}/>
  );

  const closeIcon = (
    <IoCloseSharp className={classes.Hamburguer} size={50} onClick={toggleMenu}/>
  );

  return (
    <div className={classes.mobNavigation}>
      <div className={classes.logo}>
      <Link to="/">
          <img src="/Logo.gif" alt="Logo"/>
      </Link>
      </div>
      {open ? closeIcon : hamburguerIcon}
      {open && <NavLinks />}
    </div>
  );
};

export default MobileNavigation;
