import ScrollToTop from '../../../../components/ScrollToTop'
import Bar from '../../../../components/Bar/Bar'
import MobileNavigation from '../../../../components/MobileNavigation'
import Close from '../../../../components/Close/Close'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from "framer-motion"

export default function UEDT(){
  return(
    <HelmetProvider>
    <motion.div exit={{ opacity:0 }} animate={{ opacity:1 }} initial={{ opacity:0 }}
    transition={{ ease: [0,.68,0,1.01],duration: 1.5 }}> 
      <Helmet>
       <meta charSet="utf-8" />
       <title>Lucas Zorzetto ― UEDT</title>
      </Helmet>
          <Bar/>
          <MobileNavigation/>
          <ScrollToTop/>
          <Close/>
          <div className='project-content'>
          <div className='container-project'>
          <div className="video-embed">
          <iframe src="https://player.vimeo.com/video/847902699" width="100%" height="960" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
          </div>
          <hr className="divider-small"></hr>
          <h1>Uso Excessivo da Tecnologia</h1>
          <a8>2023</a8>
          <hr className="divider-small"></hr>
          <p id="description">Explainer video for my school which was promoting for the students to produce a short video about the results of using technology exaggeratedly. The videos would to the 6th grade so it had to be in a simple way that would be easy to understand. Check out the full project on&nbsp;<a id='link' href="https://be.net/gallery/176066263/Uso-Excessivo-da-Tecnologia" target="_blank">Behance</a>!</p>
          <div className="role">
          <hr className="divider-small"></hr>
          <h1>Role</h1>
          <a1>Animation, Design and Soundtrack</a1>
          </div>
          </div>
      </div>
    </motion.div>
    </HelmetProvider>
)
}