import './Play.css'
import Bar from '../../components/Bar/Bar'
import MobileNavigation from '../../components/MobileNavigation'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from "framer-motion"
export default function Play(){
  return(
    <HelmetProvider>
    <motion.div exit={{ opacity:0 }} animate={{ opacity:1 }} initial={{ opacity:0 }}
    transition={{ ease: [0,.68,0,1.01],duration: 1.5 }}> 

      <Helmet>
       <meta charSet="utf-8" />
       <title>Lucas Zorzetto ― Play</title>
      </Helmet>
          <Bar/>
          <MobileNavigation/>
    <div className="container-play">
    <div className="play-wrapper">
    <div className="header-placeholder"></div>
      <a href='https://www.instagram.com/p/CNvpMgQnPtY/'  target="_blank"><img className="Just-Run" src="./Play-Projects/Walk Cycle.gif" alt="Walk Cycle"/></a>
      <a href='https://www.instagram.com/p/COWcPpan-_B/' target="_blank"><img className="Little-Leafs" src="./Play-Projects/Little Leafs.gif" alt="Little Leafs"/></a>
      <a href='https://www.instagram.com/p/CklIf5Nu-EZ/' target="_blank"><img className="Cel-White" src="./Play-Projects/Cel White.gif" alt="Cel White"/></a>
      <a href='https://dribbble.com/shots/20624850-Halos' target="_blank"><img className="Halos" src="./Play-Projects/Halos.gif" alt="Halos"/></a>
      <a href='https://dribbble.com/shots/21444840-RigRig' target="_blank"><img className="RigRig" src="./Play-Projects/RigRig.gif" alt="RigRig"/></a>
      <a href='https://www.instagram.com/p/Co9_xjKuGk9/' target="_blank"><img className="Door" src="./Play-Projects/Door.gif" alt="Door"/></a>
      <a href='https://dribbble.com/shots/21444706-Wires' target="_blank"><img className="Wires" src="./Play-Projects/Wires.gif" alt="Wires"/></a>
    </div>
    </div>
  </motion.div>
  </HelmetProvider>
  )
}