import './Logo.css'
import { Link } from 'react-router-dom'

const Logo = () => {

return(
<div className="logo">
    <Link to="/">
    <img src="/Logo.gif" alt="Logo"/>
    </Link>
</div>
)
}
export default Logo;