import ScrollToTop from '../../../../components/ScrollToTop'
import Bar from '../../../../components/Bar/Bar'
import MobileNavigation from '../../../../components/MobileNavigation'
import Close from '../../../../components/Close/Close'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from "framer-motion"

export default function InnerDoor(){
  return(
    <HelmetProvider>
    <motion.div exit={{ opacity:0 }} animate={{ opacity:1 }} initial={{ opacity:0 }}
    transition={{ ease: [0,.68,0,1.01],duration: 1.5 }}> 
      <Helmet>
       <meta charSet="utf-8" />
       <title>Lucas Zorzetto ― Newton's Pendulum</title>
      </Helmet>
          <Bar/>
          <MobileNavigation/>
          <ScrollToTop/>
          <Close/>
          <div className='project-content'>
          <div className="video-embed">
          <iframe src="https://player.vimeo.com/video/625339376?h=4be83ba2d1" width="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
          </div>
          <div className='container-project'>
         <hr className="divider-small"></hr>
          <h1>Newton's Pendulum</h1>
          <a8>2021</a8>
          <hr className="divider-small"></hr>
          <p id="description">Personal project for practice work with shadows, glow and play with some loop combinations.</p>
          <hr className="divider-small"></hr>
            <h1>Role</h1>
            <a1>Animation, Design and Sound design</a1>
            </div>
      </div>
    </motion.div>
    </HelmetProvider>
    )
}