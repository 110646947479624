import './Close.css'
import { Link } from 'react-router-dom'

const Close = () => {

return(
    <div>
        <Link to="/">
        <a id="close">Close</a>
        </Link>
    </div>
)
}
export default Close;