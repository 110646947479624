import ScrollToTop from '../../../../components/ScrollToTop'
import Bar from '../../../../components/Bar/Bar'
import MobileNavigation from '../../../../components/MobileNavigation'
import Close from '../../../../components/Close/Close'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from "framer-motion"

export default function LazyEyes(){
  return(
    <HelmetProvider>
    <motion.div exit={{ opacity:0 }} animate={{ opacity:1 }} initial={{ opacity:0 }}
    transition={{ ease: [0,.68,0,1.01],duration: 1.5 }}> 
      <Helmet>
       <meta charSet="utf-8" />
       <title>Lucas Zorzetto ― Lazy Eyes</title>
      </Helmet>
          <Bar/>
          <MobileNavigation/>
          <ScrollToTop/>
          <Close/>
      <div className='project-content'>
      <div className="video-embed">
      <iframe src="https://player.vimeo.com/video/926697594?h=c81928e459" width="100%" height="960" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
      </div>
      <div className="container-project">
      <hr className="divider-small"></hr>
      <h1>Lazy Eyes</h1>
      <a8>2024</a8>
      <hr className="divider-small"></hr>
      <p id="description">Late ‘23 I got inspired by a song from Interpol which said: “Like a daisy in my lazy eyes”. Initially when I listened by the 12th time I had a storm of ideas, so I right started gathering references and writing down ideas to make a project from them. It ended up being way better than I expected! Check out the full case on&nbsp;<a id='link' href="https://be.net/gallery/195201947/Lazy-Eyes" target="_blank">Behance</a>!</p>
      <hr className="divider-small"></hr>
      <h1>Role</h1>
      <a1>Animation and Design</a1>
      </div>
      </div>
    </motion.div>
    </HelmetProvider>
    )
}