import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Reel from './pages/Reel';
import Error from './pages/Error';
import Play from './pages/Play';
import SomeLoops from './pages/Home/projects/someloops';
import Equilibrate from './pages/Home/projects/equilibrate';
import LazyEyes from './pages/Home/projects/lazyeyes';
import UEDT from './pages/Home/projects/UEDT';
import SecretBranches from './pages/Home/projects/secretbranches';
import NewtonsPendulum from './pages/Home/projects/newtonspendulum';

const Home = lazy(() => import("./pages/Home"));
/*const Contact = lazy(() => import("./pages/Contact"));*/

function RoutesApp() {
  return(
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Reel" element={<Reel />} />
          <Route path="/Play" element={<Play />} />
          <Route path="*" element={<Error />} />

          <Route path="/some-loops" element={<SomeLoops />} />
          <Route path="/equilibrate" element={<Equilibrate />} />
          <Route path="/lazy-eyes" element={<LazyEyes />} />
          <Route path="/uedt" element={<UEDT />} />
          <Route path="/secret-branches" element={<SecretBranches />} />
          <Route path="/newtons-pendulum" element={<NewtonsPendulum />} />
        </Routes>
        </Suspense>
    </BrowserRouter>
  );
}

export default RoutesApp;